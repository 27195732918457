import { Component } from 'vue'
import {
  ModalProviderKey,
  IShowParams,
  ModalContextKey,
  IModalContext,
} from '~/components/infrastructure/modal/types'

export default function () {
  const modalProviderInjection = inject(ModalProviderKey, null)

  const showModal = (component: Component | Promise<Component>, params?: IShowParams) => {
    if (!modalProviderInjection) {
      throw Error('Not found ModalProvider')
    }

    return modalProviderInjection.show(component, params)
  }

  return {
    showModal,
  }
}

export function useModalContext(): IModalContext {
  const ctx = inject(ModalContextKey, null)

  if (!ctx) {
    throw Error('Not found ModalContext')
  }

  return ctx
}
